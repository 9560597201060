.volume-icon-container {
    display: flex;
    width:800px;
    height: 25px;
    position: absolute;
    justify-content: end;
    align-items: center;
    margin-top: 568px;
    margin-left: -120px;
}


.volume-icon-container img{
    width: 30px;
    height: 30px;
    
}

.volume-icon-container img:hover{
    cursor: pointer;
}

.easter-egg{
    height: 95px;
    width: 10px;
	display: flex;
	position: absolute;
    bottom: 30px;
    left: 730px;

}
