.windows-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.windows-button {
    background-color: #b1b1b1;
    border: 1px outset #b9b9b9;
    box-shadow: inset 1px 1px 1px 1px #e7e7e7;
    color: black;
    text-align: center;
    margin: 4px 2px;
    padding: 0;
    cursor: pointer;
}